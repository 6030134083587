import Vue from 'vue'
import i18n from '@/i18n/i18n.js'
import store from '@/store/store'
class common {
    // 取語系
    getLang(data) {
        if (!data) return ''
        const statusMap = {
            tw: data.tw,
            cn: data.cn,
            en: data.en,
        }
        return !statusMap[i18n.locale] ? data.default : statusMap[i18n.locale]
    }
    // 取語系
    getLangDB(data) {
        if (!data) return ''
        const statusMap = {
            tw: data.name,
            cn: data.name_cn,
            en: data.name_en,
        }
        return !statusMap[i18n.locale] ? data.name : statusMap[i18n.locale]
    }
    // 取性別
    getGender(val) {
        const statusMap = {
            1: {
                tw: '男',
                cn: '男',
                en: 'Male',
                default: '男',
            },
            2: {
                tw: '女',
                cn: '女',
                en: 'Female',
                default: '女',
            },
        }
        return !statusMap[val] ? '-' : this.getLang(statusMap[val])
    }
    // 取年齡
    getAge(val) {
        if (val === '0000-00-00' || !val) {
            return '--'
        } else {
            const ageMS = Date.parse(Date()) - Date.parse(val)
            const age = new Date()
            age.setTime(ageMS)
            const ageYear = age.getFullYear() - 1970
            return ageYear
        }
    }
    // 直接打電話 轉成href格式
    getPhone(number) {
        number = number.slice(2, 10)
        number = 'tel:+886-9' + number
        return number
    }
    // 抓日期
    getCurrentDate(index = 0) {
        const timestamp = _.now()
        const date = new Date(timestamp)

        // 修改日期
        date.setDate(date.getDate() + index)

        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')

        return `${year}-${month}-${day}`
    }
    // 掛號註記
    getPMemo(val) {
        if (!val) return '無'
        return val.substr(-1) == ',' ? val.slice(0, -1) : val
    }
    // 取 時段 文字
    getInterval(interval) {
        if (interval == 1) {
            return i18n.t('apptMgmt.am')
        } else if (interval == 2) {
            return i18n.t('apptMgmt.pm')
        } else if (interval == 3) {
            return i18n.t('apptMgmt.night')
        } else if (interval == 0) {
            return i18n.t('apptMgmt.emergency')
        } else {
            return ''
        }
    }
    // 跳通知
    notify(data) {
        const default_params = {
            duration: 5000,
            position: 'top-center',
            color: 'grey',
        }
        const payload = _.merge({}, default_params, data)
        Vue.prototype.$vs.notify(payload)
    }
    arrayToDecimal(array) {
        if (!array) return null

        return array.reduce((acc, val) => acc + (1 << val), 0)
    }
    decimalToArray(index) {
        const atemp = Array.from({ length: 14 }, (_, i) => i)
        const array = []

        for (let i = 0; index > 0; i++, index >>= 1) {
            if (index & 1) {
                array.push(atemp[i])
            }
        }

        return array
    }
    formatDate(dateTime) {
        const date = new Date(dateTime)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')

        return `${year}-${month}-${day}`
    }
    openExternalUrl(url) {
        if (localStorage.getItem('device_type') == 1) {
            window.location.href = url
        } else {
            window.open(url, '_blank')
        }
    }
    // 建立樹狀資料
    buildUnitTree() {
        // 先做個 map 把每個單位都掛一個空陣列當 children
        const map = {}
        store.state.unit_list.forEach((item) => {
            map[item.id] = { ...item, children: [] }
        })

        // 收集所有 root (main_id = 0) 的節點
        const roots = []

        // 再跑一次，把 child 加進對應父層
        store.state.unit_list.forEach((item) => {
            const node = map[item.id]
            // 假如有 main_id，而且父層也存在於 map 中，就把自己塞到父層 children
            if (item.main_id && map[item.main_id]) {
                map[item.main_id].children.push(node)
            } else {
                // main_id = 0 或找不到父層，代表自己是根節點
                if (!item.main_id) {
                    roots.push(node)
                }
            }
        })

        return roots
    }
    // 複製到剪貼簿
    async copyTextToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text)
            const notifyData = { title: '已複製到剪貼簿' }
            store.dispatch('notify', notifyData)
        } catch (err) {
            console.error('Failed to copy: ', err)
        }
    }
    // 簡易的 isset
    isset(variable) {
        return typeof variable !== 'undefined' && variable !== null
    }
}

export default new common()
