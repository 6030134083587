export default {
    main: {
        repeatLogin: '已在其他装置登入',
        connectionFailed: '网路连线异常',
        recieveNewMyTodoList: '收到新的待办事项',
        todoListDone: '有完成的交办事项',
        shiftChangeNotice: '班表变更通知',
    },
    login: {
        login: '登入',
        id: '身分证',
        phone: '电话',
        account: '帐号',
        password: '密码',
        passwordConfirm: '再次输入密码',
        rememberAccount: '记住帐号',
        forgetPassword: '忘记密码',
        register: '注册',
        choosePartner: '请选择医事机构',
        failedAlert1: '登入金钥有状况,请与系统管理员联系!',
        failedAlert2: '请输入正确资料',
        failedAlert3: '密码错误',
        failedAlert4: '找不到使用者',
        failedAlert5: '此帐号已关闭',
        failedAlert6: '密码输入错误超过4次，请等待15分钟，在尝试登入。 ',
        loginFailed: '登入失败',
        mobile: '手机号码',
        back: '返回登入',
        submit: '送出',
        sendVerifyCode: '验证码已发送至手机',
        firstName: '姓',
        lastName: '名',
        mobileVerifyCode: '手机验证码',
        mobileVerify: '手机验证',
        timeLeft: '剩余',
        seconds: '秒',
        agree: '我同意',
        selectType: '选择功能权限',
        selectTitle: '选择称谓',
        deliverFailed: '验证码发送失败',
        deliverSuccessful: '验证码已发送至手机',
        privacy: '注册合约',
        confirmLogin: '請聯絡客服進行帳號開通',
    },
    navbar: {
        doctorsStatus: '合作医师',
        offline: '暂离',
        updateInfo: '更新资讯',
        reservation: '挂号管理',
        chat: '聊天室',
        onlineStatus: '待诊',
        busyStatus: '忙碌',
        offlineStatus: '离开',
        profile: '个人资讯',
        notify: '通知',
        changePartner: '切换机构',
        logout: '登出',
        newVersionReload: '后台主机系统已更新，请立即重新整理视窗已确保资料正确性',
        later: '稍后',
        reload: '重新整理',
        closeReload: '是否关闭重新整理视窗?',
        choosePartnerGroup: '请选择医事机构',
        Online: '在线',
        Offline: '离线',
    },
    home: {
        todayAppointment: '今日挂号',
        futureAppointment: '未来挂号',
        chatRoomMsg: '聊天室讯息',
        toDoList: '待办事项',
        todayGeneralIdea: '本日概况',
        weekGeneralIdea: '本周概况',
        monthGeneralIdea: '本月概况',
        todayAppointmentUint: '诊',
        futureAppointmentUint: '诊',
        chatRoomMsgUint: '则',
        toDoUint: '项',
        reservationTotal: '预约人数',
        checkInTotal: '报到人数',
        finishTotal: '完成人数',
        peopleTotal: '人数',
        Mon: '周一',
        Tue: '周二',
        Wed: '周三',
        Thu: '周四',
        Fri: '周五',
        Sat: '周六',
        Sun: '周日',
        resetPasswordOrNot: '是否前往重新设定新密码',
        doctorJoinMsg: '欢迎加入{partnerName}，请完成个人资料的填写，并等待专员审核上线，谢谢您!',
        doctorPhoneVerifyMsg: '欢迎加入{partnerName}，请完成手机号码验证，谢谢您!',
    },
    reservation: {
        am: '上午',
        pm: '下午',
        night: '晚诊',
        other: '其他时段',
        emergency: '急诊纪录',
        agent: '代理挂号',
        special: '特约门诊',
        record: '挂号纪录查询',
        otherRWD: '其他',
        emergencyRWD: '急诊',
        agentRWD: '代挂',
        specialRWD: '特诊',
        recordRWD: '纪录',
        card: '卡片',
        table: '列表',
        doctor: '医师',
        status: '状态',
        all: '全部',
        unfinish: '未完诊',
        finish: '已完诊',
        cancel: '已取消挂号',
        cancelSystem: '逾时自动取消挂号',
        specialReservation: '新增特约挂号',
        selectDoctorMember: '请选择医师会员',
        selectPatientMember: '筛选符合民众',
        selectDate: '请选择日期',
        selectTime: '请选择时间',
        startDate: '开始日期',
        endDate: '结束日期',
        enterMinutes: '输入咨询分钟',
        enterPrice: '请输入金额',
        tip1: '请输入民众姓名或身分证字号',
        tip2: '将于选定时间前三十分钟提醒双方',
        vipMember: 'VIP会员',
        search: '搜寻',
        noData: '目前无挂号资讯',
        confirmText_1: '请确定以下资讯：',
        confirmText_2: '预约医师：',
        confirmText_3: '民众大名：',
        confirmText_4: '付费方案：',
        confirmText_5: '分钟',
        confirmText_6: '预约时间：',
        confirmText_7: '时段：',
        confirmText_8: '注意：请提醒民众在30分钟内,付完款项。',
        sms: '发会议连结',
        unSay: '未说明',
        minutesPlan: '分钟方案',
        medicalRecord: '病历',
        contactAgent: '联系代理人',
        minutes: '分',
        seconds: '秒',
        weChatCall: '微信通话',
        healthlinkCall: '远盟通话',
        lineMsg: 'LINE 讯息',
        smsText: '您将发送会议连结，请民众进入诊间。民众进入诊间后，您也会收到提醒通知。',
        smsTextCancel: '会员错过通话服务，挂号已取消。',
        man: '男',
        woman: '女',
        downloadReport: '下载挂号名单',
        pdfDownload: 'PDF下载',
        excelDownload: 'Excel下载',
        patientList: '民众名单列表',
        invite: '邀请',
        hasInvited: '已呼叫',
        notInRoom: '民众未进入诊间',
        isWaitting: '民众已进入诊间等候',
        EnterRoom: '看诊',
        times: '次',
        hasWaited: '已等候',
        copyLink: '复制视讯连结',
        copyLinkNotify: '已复制视讯连结至剪贴簿',
        site: '[到院诊]',
        switchNewVersion: '体验新版',
        Inroom: '已进入诊间',
        patient: '民众',
        familyMembers: '的家属',
    },
    apptMgmt: {
        otherInstitution: '其他机构挂号',
        my: '我',
        myself: '我',
        whosAppt: '{name}的挂号',
        myReferralAppt: '我代理的挂号',
        authDoc: '授权我的医师',
        switchOldVersion: '切回旧版',
        agent: '代理挂号',
        special: '特约挂号',
        filter: '筛选',
        todayType: '今日挂号',
        futureType: '未来挂号',
        today: '今日挂号',
        future: '未来挂号',
        am: '上午诊',
        pm: '下午诊',
        night: '晚上诊',
        spec: '特约门诊',
        emergency: '急诊',
        status: '挂号状态(全部)',
        unfinish: '未完诊',
        finish: '已完诊',
        cancel: '已取消',
        cancelSystem: '逾时自动取消挂号',
        call: '通话',
        invite: '邀请',
        enterRoom: '看诊',
        goToMr: '进入病历',
        sendMsg: '发送讯息',
        copyLink: '复制简讯连结',
        notInRoom: '民众未进入诊间',
        isWaitting: '民众已进入诊间等候',
        tag: '{name}代挂',
        tag2: '回拨给{name}',
        noData: '目前查无资料',
        shortSpec: '特约',
        onsiteVisit: '到院诊',
        freeClinicUntested: '未完成{num}项',
        freeClinicTested: '已完成{num}项',
        callBack: '通话对象：',
        csTags: '与{dname}医师的会诊',
        consultation: '会诊挂号',
    },
    advisory: {
        filter: '过滤',
        today: '今日',
        week: '本周',
        month: '本月',
        season: '本季',
        year: '本年',
        search: '搜寻',
        searchNameAndPhone: '搜寻姓名或电话',
        searchNameAndPhoneAndAccount: '搜寻姓名、电话或帐号',
        startDate: '开始日期',
        endDate: '结束日期',
        agent: '代理挂号',
        emr: '检视病历',
        video: '检视影像',
        seconds: '秒',
        quarterDefinition: '＊本季说明：ㄧ季定义为三个月分别 1-3 ｜ 4-6 ｜ 7-9 ｜ 10-12',
        nodata: '目前查无资料',
        recordFile: '录影档案',
    },
    todolist: {
        todolist: '待办事项',
        ask: '交办',
        process: '进行中',
        finish: '完成',
        nodata: '目前查无资料',
        toAsk: '交办事项',
        sendTo: '传送对象',
        content: '内容',
    },
    chat: {
        searchContact: '搜寻联络人',
        patient: '民众',
        noContact: '请前往会员管理点选讯息开始聊天',
        noMore: '没有更多',
        loading: '加载中',
        medicalStaff: '医事人员',
        addMRStaff: '新增医事人员',
        searchMRStaff: '搜寻医事人员',
        noMRStaff: '目前无医事人员',
        group: '群组',
        groupName: '群组名称',
        addGroup: '新增群组',
        noGroup: '目前无群组',
        sendMsg: '发送讯息',
        submit: '送出',
        enterGroupName: '请输入群组名称',
        selectGroupMember: '请选择群组会员',
        reselectUser: '请重新选择使用者',
        selectUser: '请选择使用者',
        excelExport: 'Excel汇出',
        msgIsSeen: '已读',
        msgIsRecycle: '点击收回此则讯息',
        recycleMsg: '已收回此笔资料',
        alertRecycleMsg: '收回此则讯息将无法复原!是否确认收回此笔资料？',
    },
    notify: {
        title: '通知',
        nodata: '无通知纪录',
    },
    member: {
        totalRecord: '笔',
        pleaseSelect: '请选择',
        selectGroup: '请选择组别',
        all: '全部',
        searchNameAndAccount: '搜寻姓名或帐号',
        search: '搜寻',
        card: '卡片',
        table: '列表',
        patientNoAuth: '民众未授权',
        personalSick: '个人病史',
        none: '无',
        patient: '民众',
        transfer: '转接医师',
        medicalRecord: '病历',
        upload: '上传',
        agent: '代理挂号',
        pleaseClick: '请按',
        noData: '目前查无资料',
    },
    membersMgmt: {
        addmember: {
            btnname: '新增會員',
            confirm: '報名',
            close_btn: '關閉',
            scan: '掃描健保卡或身分證',
            dialogtitle: '新增會員',
            name: '* 姓名',
            account: '* 身分證字號',
            cardno: '健保卡號',
            dob: '出生日期',
            selectunit: '單位',
            required: '必填',
            nounits: '需請後台管理者將您的的帳號加入單位，您才可以在所屬的單位新增會員或檢視會員。',
            err_dialog_title: '新增會員',
            gender: '性別',
            male: '男',
            female: '女',
            height: '身高',
            error: {
                ERR_0000: '新增成功',
                ERR_0001: '新增失敗 (ERR_0001)',
                ERR_0002: '單位被停用或不存在.請洽管理者協助確認 (ERR_0002)',
                ERR_0003: '新增失敗 (ERR_0003)',
                ERR_0004: '新增失敗 (ERR_0004)',
                ERR_0005: '新增失敗 (ERR_0005)',
                ERR_0006: '新增失敗 (ERR_0006)',
                ERR_0007: '新增失敗 (ERR_0007)',
                ERR_0008: 'Partner member status is disabled. Please contact partner admin for further assistance (ERR_0008)',
                ERR_0009: '新增失敗 (ERR_0009)',
                ERR_0010: 'Unit member status is disabled. Please contact partner admin for further assistance (ERR_0010)',
                ERR_0011: '單位會員已存在 (ERR_0011)',
                ERR_0012: '新增失敗 (ERR_0012)',
                ERR_0013: '帳號必須是身分證字號、居留證號、或台灣手機號碼',
                ERR_0014: '健保卡號需是12位數字 (ERR_0014)',
                ERR_0015: '無法預期的錯誤，請向系統管理者反映。 (ERR_0015)',
                ERR_0016: '無法預期的錯誤，請向系統管理者反映。 (ERR_0016)',
                ERR_0017: '無法預期的錯誤，請向系統管理者反映。 (ERR_0017)',
                SYS_ERR: '無法預期的錯誤，請向系統管理者反映。 (SYS_ERR)',
                PL_ERR: '新增失敗 (ERR_0001)',
            },
        },
        prev: '上一页',
        title: '会员管理',
        filter: '筛选',
        unit: '全部单位',
        tags: '案件类别不拘',
        disease: '个人病史不拘',
        dataLength: '笔',
        male: '男',
        female: '女',
        noAuth: '病患未授权',
        noDisease: '无个人病史',
        goToMr: '病历',
        call: '通话',
        sendMsg: '讯息',
        agent: '代理挂号',
        special: '特约挂号',
        upload: '上传医疗影像',
        addCase: '新增服务案件',
        noData: '查无资料',
        callTitle: '选择通话对象',
    },
    monitor: {
        title: '远距监看',
        refresh: '刷新',
        autoRefresh: '自动刷新',
        selectUnit: '请选择单位',
        all: '全部',
        allDays: '所有的量测',
        oneDay: '1天内量测',
        threeDays: '3天内量测',
        sevenDays: '7天内量测',
        thirtyDays: '30天内量测',
        temperature: '体温',
        weight: '体重',
        pulse_rate: '心跳',
        blood_oxygen: '血氧',
        blood_sugar_before: '血糖(饭前)',
        blood_sugar_after: '血糖(饭后)',
        blood_sugar_empty: '血糖(空腹)',
        systolic_blood_pressure: '血压(收缩压)',
        diastolic_blood_pressure: '血压(舒张压)',
        pulse_rate_bpm: '心率(血压机)',
        electrocardiography: '心电图',
        noData: '查无资料',
        noHealthData: '没有数据',
        man: '男',
        woman: '女',
        min: '最小值',
        max: '最大值',
        monitorNoAuth: '未开启远距监看功能',
        noMonitorGroup: '没有监看群组，请合作伙伴管理者至合作伙伴后台建立监控群组',
        prev: '回上一页',
    },
    opinion: {
        opinion: '文档会诊纪录',
        beInvited: '被邀请会诊项目',
        invite: '邀请会诊项目',
        beInvitedRWD: '被邀请',
        inviteRWD: '邀请',
        startDate: '开始日期',
        endDate: '结束日期',
        detail: '检视内容',
        process: '进行中',
        finish: '完成',
        noData: '目前查无资料',
        content: '内容',
        mr: '病历',
        generalPhoto: '患部照片',
        generalReport: '健检报告',
        dicom: 'DICOM',
        funduscopy: '眼底镜图片',
        ITRI_AI: '工研院眼底镜_AI',
        ultrasonograph: '超音波照片',
        ecg: '心电图',
        faceDiagnosticScope: '五官镜',
        ear: '耳道',
        nasal: '鼻腔',
        throat: '咽喉',
        oral: '口腔',
        skin: '皮肤',
        phono: '心电心音',
        video: '一般影片',
    },
    crm: {
        pageTitle: '个案管理(CRM)',
        addCase: '新增案件',
        addNotes: '新增注记',
        caseNotes: '案件注记',
        tracking: '追踪',
        track: '待追踪',
        today: '今天',
        tomorrow: '明天',
        days: '{days}天后',
        daysAgo: '{days}天前',
        assign: '交办',
        assignTo: '{from_name} 已交办给 {to_name}',
        caseAssign: '交办负责人',
        createdBy: '{name}建立',
        noData: '目前查无资料',
        patient: '民众',
        doctor: '负责人',
        caseTitle: '案件标题',
        caseType: '案件类型',
        notes: '注记',
        finish: '结案',
        finished: '已结案',
        cancel: '已取消',
        commentHistory: '历史纪录',
        selectCaseOwner: '选择案件负责人',
        sec: '{num}秒前',
        min: '{num}分钟前',
        hour: '{num}小时前',
        day: '{num}天前',
        month: '{num}个月前',
        year: '{num}年前',
        assignMsg: '[系统] {from_name}交办案件给{to_name}',
        cancelAssignMsg: '[系统] {from_name}取消交办',
        closeAll: '全部结案',
        sendMsg: '讯息',
        agent: '代理挂号',
        special: '特约挂号',
        confirmAllClose: '确定要全部案件结案？',
        close_success: '结案成功',
        noData: '尚未有服务案件',
        myself: '(自己)',
        unassigned: '未交办',
        selectCase: '选择需结案的案件:',
        assignCase: '我是负责人的案件',
        unassignedCase: '未指定负责人的案件',
        detailFinish: '[系统] {dname}结案',
        closeAll: '全部結案',
        close: '結案',
        confirm: '确定修改',
    },
    schedule: {
        schedule: '预约通知',
        addSchedule: '新增预约',
        setTime: '建立时间',
        sendTime: '预计发送时间',
        fail: '发送失败',
        finish: '已读取',
        hasSend: '已发送',
        notSend: '待发送',
        noData: '目前查无资料',
        editSchedule: '编辑预约',
        selectDateTime: '选择日期时间',
        content: '内容',
        update: '更新',
        patient: '民众',
        medicalStaff: '医事人员',
        selectPatient: '请搜索民众会员',
        selectDoctor: '请选择医师会员',
        Topatient: '发送给民众:',
        ToStaff: '发送给医事人员:',
    },
    shift: {
        shift: '班表设定',
        myShift: '个人班表',
        maxAppointment: '每诊最大预约人数',
        people: '人',
        fixedSchedule: '固定班表',
        arrangeShift: '休诊设定',
        changeTime: '变更时间',
        startTime: '开始时间',
        endTime: '结束时间',
        alertText1: '此次班表更动将会影响',
        alertText2: '诊挂号，您将需要自行通知已挂号民众做取消挂号的动作，是否仍要更动班表？ ',
        mon: '周一',
        tue: '周二',
        wed: '周三',
        thu: '周四',
        fri: '周五',
        sat: '周六',
        sun: '周日',
        am: '上午',
        pm: '下午',
        night: '晚诊',
        amRWD: '上',
        pmRWD: '下',
        nightRWD: '晚',
        shiftNote: '× 只能请假今天以后的日期(不含今天)',
        scheduledOff: '请假',
        cancelDayOff: '取消请假',
        setOffDay: '请假成功',
        shiftSetting: '每周班表设定',
        closed: '全院休诊',
    },
    authorization: {
        authorization: '授权管理',
        addAuth: '新增授权',
        manageShift: '管理班表',
        contactPatient: '与挂号民众通话',
        search: '搜寻',
        delete: '删除',
        prompt: '授权功能仅开放给医师决策权限使用',
    },
    contact: {
        title: '合作医护人员',
        subtitle: '搜寻并勾选合作的医护人员',
        search: '搜寻',
        addContact: '新增联络人',
        existContact: '存在联络人',
        totalRecord: '笔',
        noData: '目前查无资料',
        deleteContactAlert: '确定要删除此联络人?',
    },
    setWord: {
        title: '常用字句',
        desc: '常用字句分为：主观项目(S)、客观项目(O)、医事人员建议(memop)以及文字交谈(wd)，可建立常用的罐头语句供点选使用，以节省打字时间。',
        import: '汇入',
        addWord: '新增常用字句',
        noData: '目前查无资料',
        edit: '编辑',
        download: '下载模板',
        uploadFile: '上传文件',
        previewData: '预览资料',
        finish: '完成',
        downloadExample: '下载模板范例',
        onlyExcelFile: '只接受excel格式文件',
        dragFile: '将档案拖曳此处或<em>点击</em>上传',
        doctorSOAP: '医事人员S.O.A.P. 类型',
        noDoctorSOAP: '找不到医事人员S.O.A.P. 类型',
        prevStep: '上一步',
        nextStep: '下一步',
        importMultiple: '多笔汇入',
        soap_S: '主观项目',
        soap_O: '客观项目',
        soap_Memop: '医事人员建议',
        soap_WD: '文字交谈',
        diet: '饮食建议',
        diet_Memop: '饮食建议',
        waring: '請修改常用字句再儲存！',
    },
    profile: {
        title: '个人资讯',
        id: '身分证字号',
        name: '姓名',
        email: '信箱',
        doctorStatus: '审核状态',
        privileges: '功能权限',
        title: '称谓',
        mobile: '手机号码',
        mobileVerify: '手机验证',
        mobilePass: '已验证',
        mobileNotPass: '未验证',
        edit: '编辑',
        resetPassword: '修改密码',
        serviceUnit: '服务单位',
        serviceType: '服务方式',
        serviceList: '服务列表',
        addServiceList: '新增服务项目',
        add: '新增',
        noData: '目前查无资料',
        lang: '精通语言',
        fee: '咨询费率',
        dayFee: '台湾白天咨询费率',
        nightFee: '台湾夜间和急诊咨询费率',
        dayFeeChina: '大陆白天咨询费率',
        nightFeeChina: '大陆夜间和急诊咨询费率',
        NTs: '新台币',
        CNs: '人民币',
        queue: '挂号',
        division: '科别',
        educationExperience: '学经历',
        bankInfo: '银行资讯',
        bankName: '银行名称',
        bankBranch: '银行分行',
        bankAddress: '银行地址',
        bankAccount: '银行帐号',
        bankAccountName: '银行户名',
        bankSwiftCode: '银行代码',
        otherInfo: '其他资讯',
        personalPhoto: '个人照片',
        licensePhoto: '执业执照',
        selectFile: '选择档案',
        selectFileTip: '档案大小不超过1000MB',
        firstName: '姓',
        lastName: '名',
        enterText: '请输入相关字句',
        internalMD: '内科',
        surgical: '外科',
        otherSubjects: '其他科别',
        chineseMD: '中医',
        education: '学历',
        experience: '经历',
        position: '职称',
        selectDomesticOrForeign: '*选择本国与国外银行',
        resetPasswordTip: '变更密码,会重新登出, 再使用新密码登入。',
        enterNewPW: '请输入新密码',
        enterNewPWAgain: '再输入一次新密码',
        resetPWPlaceholder: '新密码, 至少6位数',
        changeMobile: '变更手机号码',
        changeMobileTip: '输入新手机号码，取得验证码，进行验证。 ',
        enterMobile: '请输入新手机号码',
        enterVerifyCode: '请输入验证码',
        timeLeft: '剩余',
        seconds: '秒',
        invalidVerifyCodeTimeLimit: '手机验证码有效期限为5分钟',
        outPatient: '到院看诊',
        online: '视讯咨询',
        mainlandChinaService: '提供大陆地区咨询',
        volunteerOnline: '为弱势民众视讯义诊',
        onsite: '实地义诊',
        localeBank: '本国银行',
        foreignBank: '国外银行',
        inProcess: '审核中',
        onBoard: '已验证',
        removeServiceUnitComfirm: '此操作将移除目前所属服务单位',
        removeServiceUnitSuccessful: '服务单位移除成功!',
        deliverVerificationSuccessful: '验证码已发送至手机',
        deliverVerificationFailed: '验证码发送失败',
        changePWSuccessful: '变更密码成功，请使用新密码重新登入。',
        changePWFailed1: '请输入至少6位数密码',
        changePWFailed2: '请输入相同密码',
        changePWFailed3: '请输入英数字混合密码',
        enterCorrectPhoneNumber: '请输入正确的手机号码',
        verificationCodeRequired: '手机验证码必填',
        imgOnlyTips: '上传文件只能是图片格式',
        sizeLimitTips: '图片大小不能超过 20MB',
        phoneReqiredTips: '欢迎加入{name}，请完成手机号码验证，谢谢您!',
        uploadCheck: '请上传.jpg或.png档案格式',
    },
    log: {
        title: '操作纪录',
        startDate: '开始日期',
        endDate: '结束日期',
        noData: '目前查无资料',
    },
    privacy: {
        mainTitle: '医护人员注册合约',
        title1: '警语：',
        desc1: '本人身为医护人员充分了解医师法第 11 条及政府主管机关对远距诊察的相关法令和规范，本人并同意遵守之。本人了解除了主管机关核准从事远距医疗的特定地区，民众及情况外，远距视讯咨询可做为会员到医疗院所就医前和就医后的辅助咨询工具，提供会员更即时和方便的保健咨询服务，以提升医病关系并恊助建立家庭医师制度。',
        title2: '平台免责声明：',
        desc2: '本人了解并同意贵公司谨提供通讯平台供本人和客户做咨询服务，不负责处理任何因此咨询服务所产生本人与客户的纠纷，亦不必分摊或给付任何纠纷的赔偿。',
        title3: '咨询过程录音录影：',
        desc3: '本人了解并同意贵公司系统在咨询过程执行全程的录音或录影，做为收取咨询费用之依据参考。',
        title4: '咨询服务费：',
        desc4: '本人了解并同意贵公司依本人或服务的医疗机构自订的费率代向客户收取咨询费用，并同意支付 3% 的金流服务费。本公司同意在每月的 15 号结算前一个月份的咨询费用，以银行转账的方式汇款到本人或服务的医疗机构指定的银行账户，并了解银行将会收取 15 至 30 元不等的汇款费用。',
        title5: '平台服务费：',
        desc5: '本公司平台提供订阅或分润两种方式支付平台服务费，本人同意依双方签订的合同支付平台服务费费用。',
        title6: '客户个资之使用：',
        desc6: '本人了解客户一旦预约与医护人员进行健康咨询，即代表客户授权本人及所属医疗机构相关医护人员查看其健康咨询相关的个人信息。并授权本人根据需要将其健康咨询相关信息转授权给指定的转诊及会诊医护人员及其所属医疗机构。但本人承诺不得将会员相关个人信息复制、传送或出售给未经会员授权或非必要转诊及会诊的其他第三方。如有违反，同意依法赔偿受侵权的客户。',
        title7: '取消医护人员会员权利：',
        desc7: '本人了解并同意贵公司为维护服务之品质, 对于不具有医护人员执照和资格的会员，以及不当或恶意使用本服务之医护人员，保有单方面取消该会员资格之决定权利。 ',
        title8: '个资安全及隐私权声明：',
        desc8_1: '关于本公司对于您个人资料保护，请详细阅读本服务的隐私权声明',
        desc8_2:
            '医生马上看平台为健康联网资讯服务股份有限公司所经营，为了维护个人隐私权与支持个人资料保护，本公司谨以下列声明向您说明所收集个人资料之目的，类别，利用范围及方式，以及您所得行使之权利等事项；如果对于本服务的隐私权声明，以下相关告知事项，或是与个人资料保护有关之相关事项有任何疑问，可以和本公司客服中心连络，本公司将尽快回覆说明。',
        desc8_3: '适用范围：本服务隐私权声明及其所包含的告知事项，仅适用于服务所拥有及经营的网站。本服务网站可能包括许多连结、或是其他合作伙伴及商家，其有关隐私权声明及个资保护有关之告知事项，请参阅各该连结网站、合作伙伴或是商家之网站。',
        desc8_4:
            '个人资料搜集之目的与类别：本公司为了提供电子商务服务、售后服务、履行法定或合约义务、保护当事人及相关利害关系人之权益、行销、客户管理与服务、以及和与营业登记项目或组织章程锁定之业务等目的，依照各服务之性质，有可能会收集您的姓名、联络方式（包含但不限于电话、email 及地址等）、未完成收款或付款所需之资料、IP 地址、所在地点、上传的病历、医疗图像、健康检查、影音以及其他得以直接或间接辨识使用者身分之个人资料。此外，为提升服务品质，本公司会依照所提供服务之性质，记录使用者的 IP 地址、及在本服务相关网站或 App 内的浏览活动（例如，使用者说使用的软硬体、所点选的网页、下载的 APP）等资料，但是这些资料经工作流量分析和网路行为调查，以便于改善本服务的相关网站的服务品质，不会和特定个人相联系。 ',
        desc8_5:
            '个人资料的利用：服务所搜集的足以识别使用者身份的个人资料，包含但不限于商业登记资料、法定代理人姓名、地址、身分证字号；若为自然人时，则为个人姓名、地址、身分证字号等相关资料，仅提供本公司于内部依照搜集之目的进行处理和利用，除非事先说明、或为完成提供服务或履行合约义务之必要、或依照相关法令规定或有权主管机关之命令或要求，否则本公司不会将涉及使用者身分的个人资料提供给第三人，包括境内及境外，或作收集目的之外之使用。在法令所定应保存之期限内，本公司会持续保管、处理及利用相关资料。 ',
        desc8_6: '资料安全：本公司将以与产业标准之合理技术和程序，维护个人资料之安全。 ',
        desc8_7: '资料当事人之权利：资料当事人可以请求查询、阅覧本人的个资或要求给予复制本。若您的个资有变更、或发现您的个资不正确，可以向本公司要求修改或更正。当收集个人资料之目的消失或期限届满时，应可要求删除、停止处理或利用个人资料。但因本公司执行业务所得必须者，不在此限。 ',
        desc8_8: 'Cookie : 为了便利使用者，本公司网站可能会读取储存在使用者电脑中的 cookie 资料。使用者可经由浏览器的设定、取消、或限制此项功能，但可能因此无法使用部分网站功能。若你想知道如何取消、或限制此项功能，请与本公司客服中心连络。 ',
        desc8_9:
            '影响：如果您不愿提供各项服务或交易所要求的相关个人资料予本公司，并且同意本公司就该等个人资料依法令规定、以及隐私权声明、及其相关告知内容为相关之个人资料搜集、处理、利用及国际传输，本公司将尊重您的决定，但依照各该服务之性质或条件，您可能因此无法使用该等服务或完成相关交易，本公司并保留是否同意提供该等相关服务或完成相关交易之权利。 ',
        desc8_10: '修订之权利：本公司有权随时修订本隐私权声明及相关告知事项，于变更与修订后公布在本公司之网站及 APP 内，不另行个别通知，您可以随时在公司网站及 APP 内详阅修订后的隐私权声明及相关告知事项。 ',
    },
    emr: {
        male: '男',
        female: '女',
        agentAppt: '代理挂号',
        contactAgent: '联系代理人',
        transfer: '转接医师',
        done: '完诊',
        unfinish: '未完诊',
        finish: '已完诊',
        exit: '离开',
        backToAppt: '返回本次看诊',
        exitTip: '请问{name}的挂号是否已完诊？',
        transferTip: '已转接请稍后',
        returnToCallingQueue: '返回通话中挂号',
        appt: {
            patientNotes: '民众备注',
            subjective: '民众主观自觉征候',
            objective: '医疗人员客观诊断评估',
            ICD10: '国际疾病分类码',
            medicalPlan: '药嘱',
            suggestion: '建议纪录',
            enterMedicalPlan: '输入ICD10',
            enterMedicalCode: '输入药品代号',
            importMedicalPack: '汇入处方集，输入代码',
            exportMedicalPack: '汇出处方签',
            apptHistory: '看诊纪录',
            ICD10NoData: '国际疾病分类码未填写',
            copyObjective: '代入医师主诉',
            copyICD10: '代入ICD',
            copyMedicalPlan: '代入药嘱',
            copySuggestion: '代入建议纪录',
            copyToCurrentNote: '套用至本次看诊',
            code_ICD: '代号',
            name_ICD: '名称',
            remarks_ICD: '备注',
            code_p: '代号',
            name_p: '药品处置名称',
            unit_p: '单位',
            dose_p: '一次量',
            frequency_p: '用法',
            route_p: '途径',
            days_p: '天数',
            total_p: '总量',
            daily_p: '日剂量',
            daily: '剂量',
            frequency: '频次',
            notes_p: '用药说明',
            personalSickHistory: '个人病史',
            allergy: '过敏事项',
            date: '看诊日期',
            interval: '看诊时段',
            service: '服务项目',
            saving: '储存中',
            saved: '已储存',
            continuousPrescription: '连续处方签',
            prescription: '处方签',
            checkPrescription: '查看处方签',
            phone: '电话',
            mr_id: '病历号',
            name: '姓名',
            sex: '性别',
            birth: '年龄',
            did: '医师代号',
            dname: '医师',
            date: '看诊日',
            number: '诊号',
            CaseType: '案类',
            division: '科别',
            am: '上午诊',
            pm: '下午诊',
            night: '晚诊',
            saveRx: '储存处方集',
            rxName: '处方集名称',
            rxCode: '处方集代码',
            rxContent: '处方集内容',
            duplicateName: '此名称重复',
            duplicateCode: '此代码重复',
            cart_error: '请选择推荐商品再送出',
            RecommendProduct: '推荐商品',
            goProductShop: '前往商品頁',
            AddRecommend: '选择推荐商品',
            ViewProductimg: '检视图片',
            SendRecommend: '送出',
            Recommend: '推荐商品',
            ProductName: '商品名称',
            ProductNumber: '商品数量',
            Price: '商品价格',
            Description: '商品描述',
            Image: '检视图片',
            DataProcessing: '资料处理中',
            CheckSend: '确认要送出推荐商品，送出后将无法修改',
            Manufacturer: '供应商',
            SearchRecommend: '搜寻商品',
            ChooseProduct: '选择常用商品',
            SetProduct: '设成常用商品',
            ChooseProductError: '请在下方推荐商品表单搜寻商品',
            UseMyProduct: '套用常用商品',
            SetRecommProduct: '請先新增推薦商品',
            UseProduct: '套用',
            MyRecommend: '歷史推薦商品',
            PatientName: '民众姓名',
        },
        base: {},
        lifeHealth: {},
        bioData: {},
        dicom: {},
        healthdata: {
            afib: '心房颤动',
            arr: '心律不整',
            pc: '早期收缩',
            ihb: '不规则心跳',
        },
        healthreport: {
            checkup_title: '成人健检报告',
        },
    },
    apptHistory: {
        prev: '上一页',
        title: '挂号纪录',
        downloadReport: '下载挂号名单',
        filter: '筛选',
        medicalInstitution: '医事机构',
        searchPatient: '搜寻民众姓名、电话或身分证',
        all: '全部',
        week: '本周',
        month: '本月',
        sevenDays: '7天内',
        thirtyDays: '30天内',
        startDate: '开始日期',
        endDate: '结束日期',
        site: '看诊方式(全部)',
        videoChat: '视讯诊',
        onsiteVisit: '到院诊',
        status: '挂号状态(全部)',
        validQueue: '有效挂号',
        unfinish: '未完诊',
        finish: '已完诊',
        cancel: '全部取消',
        patientCancel: '民众取消',
        systemCancel: '系统取消',
        type: '挂号类型(全部)',
        generalAppt: '一般挂号',
        emergencyAppt: '急诊挂号',
        specAppt: '特约挂号',
        proxy: '挂号者(全部)',
        patientProxy: '民众挂号',
        doctorProxy: '医事人员挂号',
        showVideo: '显示有录影档的挂号',
        limitPerPage: '每页显示笔数',
        limit: '{num}笔',
        mr: '病历',
        am: '上午诊',
        pm: '下午诊',
        night: '晚诊',
        male: '男',
        female: '女',
        recordFile: '录影档案',
        referAppt: '{name}代理挂号',
        service: '服务项目：',
        suggestion: '建议纪录：',
        noData: '查无资料',
    },
    stream: {
        inviteBtn: '邀请民众家属加入',
        inviteTitle: '选择邀请对象',
        invite: '邀请',
        notify_title: '{name}邀请您加入视讯',
        notify_content: '{dname}正在对{pname}进行关怀视讯，<a class="item-link external" href="*url*" target="_blank">点击此连结</a>加入视讯',
        msg: '{dname}正在对{pname}进行关怀视讯，点击连结加入视讯 *url*',
    },
    // 元件
    components: {
        msg: {
            msg: '讯息',
            setWord: '常用字句',
            apply: '套用',
            noSetWord: '没有常用字句可使用，请前往系统设定＞常用字句进行建立',
            createNow: '马上建立',
            content: '内容',
            send: '传送',
        },
        call: {
            call: '通话',
            useMic: '无法进行通讯，请允许使用麦克风与摄影机?',
            patientOffline: '民众目前未上线',
            doctorOffline: '医师目前未上线',
            offline: '离线',
            busy: '忙碌',
            leave: '暂离',
            medicalStaff: '民众所属单位医事人员',
            patient: '民众',
        },
        agent: {
            videoChat: '视讯看诊',
            onsiteVisit: '到院看诊',
            selectedDoctor: '挂号医师',
            callTo: '医师回拨对象',
            problem: '咨询问题',
            fee: '费用',
            peopleNum: '人数',
            doctorLeave: '医师请假',
            appointment: '预约',
            timeOut: '时段已过',
            am: '上午',
            pm: '下午',
            night: '晚诊',
            confirmText_1: '请确定以下资讯：',
            confirmText_2: '预约医师：',
            confirmText_3: '民众大名：',
            confirmText_4: '付费方案：',
            confirmText_5: '预约时间：',
            confirmText_6: '日, 时段：',
            confirmText_7: '注意：请提醒民众在30分钟内,付完款项。 ',
            doctorNoService: '该医师未开班，请重新搜寻',
            partner: '医事机构',
        },
        specAgent: {
            specAppt: '特约挂号',
            partner: '医事机构',
            doctor: '医师',
            selectDoctorMember: '请选择医师会员',
            myself: '(自己)',
            myContact: '我的联络人',
            whosDoctor: '{partnerName}的医师',
            date: '日期',
            selectDate: '选择日期',
            totalTime: '咨询时长(分钟)',
            enterMinutes: '输入咨询分钟',
            patient: '民众',
            patientTip: '请输入民众姓名或身分证字号',
            selectPatientMember: '筛选符合民众',
            time: '时间',
            timeTip: '将于选定时间前三十分钟提醒双方',
            selectTime: '选择时间',
            amount: '金额',
            enterAmount: '请输入金额',
            vipMember: 'VIP会员',
            newSpecAppt: '新增特约挂号',
            communicationType: '看诊方式',
            videoChat: '视讯看诊',
            onsiteVisit: '到院看诊',
        },
        apptBtn: {
            apptBtn: '挂号',
            appt: '代理挂号',
            specAppt: '特约挂号',
            switch: '切换',
            videoChat: '视讯看诊',
            onsiteVisit: '到院看诊',
            patient: '民众',
            patientTip: '请输入民众姓名或身分证字号',
            doctor: '医师',
            myself: '(自己)',
            myContact: '我的联络人',
            whosDoctor: '{partnerName}的医师',
            callBack: '民众代理人(医师回拨对象)',
            fee: '费用',
            problem: '咨询问题',
            dateTimeSetting: '日期时间设定',
            peopleNum: '人数',
            doctorLeave: '医师请假',
            appointment: '预约',
            timeOut: '时段已过',
            time: '时间',
            timeTip: '将于选定时间前三十分钟提醒双方',
            totalTime: '咨询时长(分钟)',
            patientInPerson: '病人本人',
            inPerson: '本人',
            am: '上午',
            pm: '下午',
            night: '晚诊',
            minutes: '分钟',
            confirmText_1: '请确定以下资讯：',
            confirmText_2: '预约医师：',
            confirmText_3: '民众大名：',
            confirmText_4: '付费方案：',
            confirmText_5: '咨询时长：',
            confirmText_6: '预约日期：',
            confirmText_7: '预约时段：',
            confirmText_8: '预约时间：',
            confirmText_9: '注意：请提醒民众在30分钟内,付完款项。 ',
            errorMsg1: '请选择民众',
            errorMsg2: '请选择医师',
            errorMsg3: '请选择日期',
            errorMsg4: '请输入费用',
            errorMsg5: '请选择时间',
            errorMsg6: '请选择咨询时长',
            selectCsDoc: '选择会诊医师',
            consultation: '会诊挂号',
        },
        upload: {
            title: '医疗影像上传',
            patient: '民众',
            accountTip: '请输入民众帐号',
            patientTip: '请输入民众姓名或身分证字号',
            uploadType: '档案分类',
            notes: '备注',
            selectFile: '选择档案',
            upload: '上传',
            feeBack: {
                noPatient: '未选择民众',
                noType: '未选择上传档案类型',
                noFile: '未选择上传档案',
                upload_success: '上传成功',
            },
            type: {
                generalPhoto: '患部照片',
                generalReport: '检验报告',
                dicom: 'DICOM',
                funduscopy_R: '眼底镜图片-右眼',
                funduscopy_L: '眼底镜图片-左眼',
                ITRI_AI: '工研院眼底镜AI',
                ultrasonograph: '超音波照片',
                ecg: '心电图',
                ear: '五官镜-耳道',
                nasal: '五官镜-鼻腔',
                throat: '五官镜-咽喉',
                oral: '五官镜-口腔',
                skin: '五官镜-皮肤',
                phono: '心电心音',
                video: '一般影片',
            },
            accept: {
                0: '允許.pdf或.jpg、.png等圖片檔案',
                1: '允許.pdf或.jpg、.png等圖片檔案',
                2: '只允許.dcm的檔案',
                3: '允許.pdf或.jpg、.png等圖片檔案',
                4: '允許.pdf或.jpg、.png等圖片檔案',
                5: '允許.pdf或.jpg、.png等圖片檔案',
                6: '允許.pdf或.jpg、.png等圖片檔案',
                7: '允許.pdf或.jpg、.png等圖片檔案',
                8: '允許.pdf或.jpg、.png等圖片檔案',
                9: '允許.pdf或.jpg、.png等圖片檔案',
                10: '允許.pdf或.jpg、.png等圖片檔案',
                11: '只允許影片的檔案',
            },
        },
        sendMsg: {
            title: '{name}的讯息视窗',
            retractMsg: '{name}已收回讯息',
            msgIsSeen: '已读',
            send: '传送',
            retractTip: '确认收回此笔资料？',
        },
        selectPartner: {
            choosePartner: '请选择医事机构',
            today: '今日：',
            all: '所有：',
        },
    },
    // 醫事人員類別
    staffType: {
        Doc: '医师',
        Nur: '护理师',
        Pha: '药师',
        Cus: '客服',
        Care: '照服员',
        Dietitian: '营养师',
        Psy: '咨商心理师',
        Clinical: '临床心理师',
        Phy: '物理治疗师',
        Func: '职能治疗师',
        Heal: '健康管理师',
        Radio: '医事放射师',
        Exam: '医检师',
        Case: '个案管理师',
        HSec: '健康秘书',
        Vol: '志工师',
    },
    // 合作夥伴
    partner: {
        KeelungChangGungMemorialHospital: '基隆长庚医院',
        AllianceTitle: '远距之爱',
        ChengHsinTitle: '振兴医院',
        CLCTitle: 'CLC CARE',
        PingChrisTitle: '屏东基督教医院',
        CamiansaintTitle: '罗东圣母医院',
        CurdrTitle: '医生马上看',
    },
    // element UI 元件
    el: {
        colorpicker: {
            confirm: '确定',
            clear: '清空',
        },
        datepicker: {
            now: '此刻',
            today: '今天',
            cancel: '取消',
            clear: '清空',
            confirm: '确定',
            selectDate: '选择日期',
            selectTime: '选择时间',
            selectDateTime: '选择日期时间',
            startDate: '开始日期',
            startTime: '开始时间',
            endDate: '结束日期',
            endTime: '结束时间',
            prevYear: '前一年',
            nextYear: '后一年',
            prevMonth: '上个月',
            nextMonth: '下个月',
            year: '年',
            month1: '1 月',
            month2: '2 月',
            month3: '3 月',
            month4: '4 月',
            month5: '5 月',
            month6: '6 月',
            month7: '7 月',
            month8: '8 月',
            month9: '9 月',
            month10: '10 月',
            month11: '11 月',
            month12: '12 月',
            week: '周次',
            weeks: {
                sun: '日',
                mon: '一',
                tue: '二',
                wed: '三',
                thu: '四',
                fri: '五',
                sat: '六',
            },
            months: {
                jan: '一月',
                feb: '二月',
                mar: '三月',
                apr: '四月',
                may: '五月',
                jun: '六月',
                jul: '七月',
                aug: '八月',
                sep: '九月',
                oct: '十月',
                nov: '十一月',
                dec: '十二月',
            },
        },
        select: {
            loading: '加载中',
            noMatch: '无匹配数据',
            noData: '无数据',
            placeholder: '请选择',
        },
        cascader: {
            noMatch: '无匹配数据',
            loading: '加载中',
            placeholder: '请选择',
            noData: '暂无数据',
        },
        pagination: {
            goto: '前往',
            pagesize: '条/页',
            total: '共 {total} 条',
            pageClassifier: '页',
        },
        messagebox: {
            title: '提示',
            confirm: '确定',
            cancel: '取消',
            error: '输入的数据不合法!',
        },
        upload: {
            deleteTip: '按 delete 键可删除',
            delete: '删除',
            preview: '查看图片',
            continue: '继续上传',
        },
        table: {
            emptyText: '暂无数据',
            confirmFilter: '筛选',
            resetFilter: '重置',
            clearFilter: '全部',
            sumText: '合计',
        },
        tree: {
            emptyText: '暂无数据',
        },
        transfer: {
            noMatch: '无匹配数据',
            noData: '无数据',
            titles: ['列表 1', '列表 2'],
            filterPlaceholder: '请输入搜索内容',
            noCheckedFormat: '共 {total} 项',
            hasCheckedFormat: '已选 {checked}/{total} 项',
        },
        image: {
            error: '加载失败',
        },
        pageHeader: {
            title: '返回',
        },
        popconfirm: {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        },
    },
    //飲食管理
    dietmgmt: {
        dietmgmt: '饮食管理',
        Height: '身高(CM)',
        Weight: '体重(KG)',
        NoData: '无资料',
        Analyze: '待分析',
        MealDate: '用餐日期',
        MealDescription: '民众餐点描述',
        EstimatedKCals: '民众估计卡路里',
        FoodName: '食物名称',
        cals: '卡路里',
        ApplyMealDescription: '套用餐点描述',
        ApplyEstimatedKCals: '套用估计卡路里',
        Suggest: '建议',
        DietAnalyze: '饮食分析',
        NextPhoto: '下一张',
        Carbohydrate: '碳水化合物',
        Protein: '蛋白质',
        Fat: '脂肪',
        Water: '饮水量',
        Gram: '公克',
        Ml: '(毫升)',
        Status: '状态',
        WholeMilk: '全脂牛奶',
        LowMilk: '低脂牛奶',
        SkimMilk: '脱脂牛奶',
        Fruit: '水果类',
        Vegetables: '蔬菜类',
        Staple: '主食类',
        HighFatMeat: '肉类(高脂)',
        MediumFatMeat: '肉类(中脂)',
        LowFatMeat: '肉类(低脂)',
        MediumLegumes: '豆製品(中脂)',
        LowLegumes: '豆製品(低脂)',
        Grease: '油脂类',
        Quantity: '数量',
        TodaysCarbohydrate: '本日碳水',
        TodaysProtein: '本日蛋白质',
        TodaysFat: '本日脂肪',
        TodaysCal: '本日卡路里',
        Unit: '单位(份)',
        Units: '份',
        analyze: '分析',
        DietInformation: '饮食资讯',
        MealTime: '用餐时间',
        MealDescription: '民众描述',
        kcal: '大卡',
        Save: '儲存',
        Calorie: '卡路里',
        UpdateRecomm: '修改建议值',
        FirstMeal: '第一餐',
        SecondMeal: '第二餐',
        ThirdMeal: '第三餐',
        ForthMeal: '第四餐',
        FifthMeal: '第五餐',
        SixthMeal: '第六餐',
        Recomm: '建议值：',
        notSetRecomm: '建议值未设定',
        Save: '储存',
        noData: '未上传',
        Analyzed: '已分析',
        call: '拨打',
    },
    // 系統訊息
    message: {
        data_not_input: '资料未输入',
        upload_success: '上传成功',
        upload_error: '上传失败',
        upload_file_error: '上传失败!请检查档案大小或档案格式!',
        data_apdate_success: '资料更新成功',
        search_full_message: '请于搜寻列表输入完整的身分证/电话/居留证!',
        search_full_name_account: '请于搜寻列表输入完整的姓名或帐号!',
        confirm_delete_record: '确认删除此笔资料',
        send_success: '传送成功',
        send_error: '传送失败',
        delete_success: '删除成功',
        delete_error: '删除失败',
        register_again: '重新挂号',
        register_success: '挂号成功',
        register_error: '挂号失败',
        register_finish: '完成看诊',
        checkField: '请检查相关栏位!',
        update_success: '更新成功',
        update_error: '更新失败',
        create_success: '新增成功',
        create_error: '新增失败',
        save_success: '储存成功',
        save_error: '储存失败',
        invite_success: '邀请成功',
        invite_error: '邀请失败',
        copy_success: '复制成功',
        copy_error: '复制失败',
    },
    //問卷管理
    Question: {
        questionmgmt: '问卷管理',
        writer: '填写人',
        answer_member: '查看回覆',
    },
    // 常用彈出視窗元件
    popup: {
        alert: '提示',
        confirm: '确定',
        cancel: '取消',
        add: '新增',
        save: '储存',
        update: '更新',
        edit: '编辑',
        reset: '重设',
    },
    freeclinic: {
        freeclinicEventList: '现在没有进行中的义检活动，或您未参加活动',
    },
}
